<template>
  <div class="my-page-open my-page" :class="{skeleton : !state.loaded }">
    <div class="top">
      <ul class="nav">
        <li class="nav-item" v-for="(m, idx) in state.menus" :key="idx">
          <router-link :to="m.path" :class="[$route.path === m.path ? 'active btn-bordered-point': 'btn-light']" class="btn">{{ m.title }}</router-link>
        </li>
        <!--        <li class="nav-item blank" v-if="state.projects.length">-->
        <!--          <a href="/manage/project/ongoing" class="btn btn-bordered-secondary font-sm" v-if="state.projectType === 'reward'" @click.prevent="$store.commit('setSwingMessage', '준비 중입니다.')">-->
        <!--            <span>진행자 전용 페이지</span>-->
        <!--          </a>-->
        <!--        </li>-->
      </ul>
    </div>
    <div class="content">
      <template v-if="state.projects.length || !state.loaded">
        <ul class="tight clearfix">
          <li v-for="p in state.projects" :key="p.projectSeq">
            <Card
                page="open"
                :projectSeq="p.projectSeq"
                :projectType="state.projectType"
                :thumbFilePath="p.thumbFilePath"
                :projectName="p.projectName"
                :progressOrder="p.progressOrder"
                :period="p.period"
                :badge="p.badge"
                :regState="p.regState"
                :createDate="p.createDate"
                :partnership="p.partnership"
                :builderSeq="$store.state.account.memberSeq"
                :remove="remove"
                :mainImagePosition="p.mainImagePosition"
                :skeleton="!state.loaded"
            />
          </li>
        </ul>
        <div class="page">
          <Pagination :info="state.pagination" :change="load" v-if="state.pagination"/>
        </div>
      </template>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import Card from "@/components/Card.vue";
import router from "@/scripts/router";
import NoData from "@/components/NoData";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";
import Pagination from "@/components/Pagination.vue";

function Component(initialize) {
  this.name = "pageMyPageOpen";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Pagination, NoData, Card},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "진행 프로젝트");

      const paths = router.app.$route.path.split("/");
      state.projectType = paths[paths.length - 1];
      state.menu = state.menus[0];

      for (let i in state.menus) {
        if (state.menus[i].path === router.app.$route.path) {
          state.menu = state.menus[i];
          break;
        }
      }

      load();
    });

    const state = reactive({
      loaded: false,
      projectType: "",
      pagination: null,
      menu: {
        name: "",
        title: "",
        path: ""
      },
      menus: [
        {name: "reward", title: "후원형", path: "/mypage/open/reward"},
        {name: "invest", title: "증권형", path: "/mypage/open/invest"},
        {name: "mock", title: "모의 투자", path: "/mypage/open/mock"},
      ],
      projects: [{
        projectSeq: 211,
        projectName: "Please wait a moment",
        imgUrl: "/uploads/invest/main/MAIN_20211202045326925.jpg",
        regState: "Wait",
        title: "Please wait a moment",
        period: "Please wait a moment",
        badge: "Wait",
        partnership: "없음",
        createDate: "Please wait a moment",
      }, {
        projectSeq: 155,
        projectName: "Please wait a moment",
        imgUrl: "/uploads/invest/main/MAIN_20211202050102820.jpg?w=600&h=450&lossless=1",
        regState: "Wait",
        title: "Please wait a moment",
        period: "Please wait a moment",
        partnership: "없음",
        badge: "Wait",
        createDate: "Please wait a moment",
      }]
    });

    const load = (num) => {
      const args = {
        pageIndex: num || 1,
        pageSize: 10,
        pageUnit: 10,
      };

      num && window.scrollTo({top: 0, left: 0, behavior: "smooth"});

      state.loaded = false;
      http.get("/api/build/projects/" + state.projectType, args).then(({data}) => {
        state.loaded = true;
        state.projects = [];
        state.pagination = data?.body?.paginationInfo;

        for (let p of data.body.list) {
          const durations = [];

          if (state.projectType === "invest") {
            p.badge = p.regStateName;
            p.period = "- 펀딩 기간: ";
            p.partnership = "- 파트너십: " + (p.partnershipFlag === "Y" ? p.partnershipName || "(N/A)" : "아니오");
            p.createDate = "- 등록일시: " + p.createDate;

            if (p.investStartDate) {
              durations.push(lib.getDateFormat(p.investStartDate, "yyyy-MM-dd"));

              if (p.investEndDate) {
                durations.push(lib.getDateFormat(p.investEndDate, "yyyy-MM-dd"));
              }

              p.period += durations.join(" ~ ");
            } else {
              p.period += "(N/A)";
            }
          } else {
            if (p.regState === "STATUS_0006") {
              switch (p.progressOrder) {
                case 0:
                  p.badge = "펀딩 대기 중";
                  break;

                case 1:
                  p.badge = p.successFailFlag === "S" ? "펀딩 성공" : "진행 중";
                  break;

                case 2:
                  p.badge = p.successFailFlag === "S" ? "펀딩 성공" : p.successFailFlag === "F" ? p.fundingType === "A" ? "펀딩 실패" : "펀딩 마감" : "";
                  break;
              }
            } else {
              p.badge = definitions.codes.obj.projectStatus[p.regState];
            }

            p.period = "- 펀딩 기간: ";
            p.partnership = "- 파트너십: " + (p.partnershipFlag === "Y" ? p.partnershipName || "(N/A)" : "아니오");
            p.createDate = "- 등록일시: " + p.createDate;

            if (p.investStartDate) {
              durations.push(lib.getDateFormat(p.investStartDate, "yyyy-MM-dd"));

              if (p.investEndDate) {
                durations.push(lib.getDateFormat(p.investEndDate, "yyyy-MM-dd"));
              }

              p.period += durations.join(" ~ ");
            } else {
              p.period += "(N/A)";
            }
          }

          if (!p.projectName) {
            p.projectName = "(N/A)";
          }

          state.projects.push(p);
        }
      }).catch(httpError(() => {
        state.loaded = true;
      }));
    };

    const remove = (seq) => {
      store.commit("confirm", {
        message: "선택하신 프로젝트를 삭제하시겠습니까?",
        allow() {
          http.delete(`/api/build/projects/${state.projectType}/${seq}`).then(() => {
            load();
            store.commit("setSwingMessage", "삭제하였습니다.");
          });
        }
      });
    };

    return {component, state, remove, load};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-open {
  .content {
    ul {
      margin: 0 $px-15 0 $px-15;

      li {
        float: left;
        width: calc(100% / 2);
        padding: 0 $px15 $px35 $px15;
      }
    }
  }

  @media(min-width: 992px) {
    .nav > li.blank {
      float: right;
      margin-right: 0;
    }
  }

  @media(max-width: 767px) {
    .content ul li {
      width: 100%;
    }
  }

  @media(max-width: 991px) {
    .content {
      padding-right: 0;

      .row {
        .thumb > div .img {
          border-radius: $px4 $px4 0 0;
        }

        .texts {
          padding-left: $px30;
          padding-right: $px30;
        }
      }
    }
  }

  &.skeleton {
    .nav li a {
      @include skeleton;
    }

    .content .row {
      .thumb .img {
        @include skeleton;
      }

      .texts {
        .badges .badge,
        .title a,
        .partnership > span {
          @include skeleton;
        }

        .date > span {
          @include skeleton;

          .bar {
            visibility: hidden;
          }
        }
      }

      .actions {
        @include skeleton;

        a {
          visibility: hidden;
        }
      }
    }
  }
}
</style>